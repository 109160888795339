import React from 'react'
import Form from '../../components/form';
// import marketingbanner1 from '../../assets/marketing-strategy.jpg';
import ContactSection from '../../components/contact-section';
import { Helmet } from 'react-helmet';


function GraphicDesign(props) {
    return (
        <>
                                      <Helmet>
        <title>Graphic Design - Nova Marketing</title>
        <meta name="keywords" content="Graphic Design" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12 breadcrumb-container'>
                    <h1>Graphic Design
                    </h1>
                    <ul className="breadcrumbs">
                            <li><a href="/">Home</a></li>
                            <li>Graphic Design
                            </li>
                        </ul>
                    
                    </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 content-style-03 py-5 my-5'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12'>
                    <h5>Design Masters</h5>
                    <h2>Graphic Design Services

                    </h2>
    <p>At <strong>Nova</strong>, we provide exceptional graphic design services that bring your brand’s vision to life. Our team of creative designers is committed to delivering visually striking and effective design solutions that enhance your brand identity and communication. Our Graphic Design Services Include:</p>


    <ul>
        <li><strong>Brand Identity Design:</strong> We create memorable brand identities that include logos, color schemes, and typography, ensuring your brand stands out in the competitive crypto market.</li>
        <li><strong>Marketing Materials:</strong> From brochures and flyers to social media graphics and banners, we design marketing materials that capture your audience’s attention and convey your message effectively.</li>
        <li><strong>Website Graphics:</strong> We design eye-catching graphics for your website, including headers, icons, and infographics, to enhance user experience and engagement.</li>
        <li><strong>Presentation Design:</strong> Our team designs professional and engaging presentations that effectively communicate your ideas and strategies to stakeholders and clients.</li>
        <li><strong>Custom Illustrations:</strong> We create unique and tailored illustrations that add a distinctive touch to your brand’s visual content.</li>
        <li><strong>UI/UX Design:</strong> We focus on designing intuitive and visually appealing user interfaces and experiences that improve user interaction and satisfaction.</li>
    </ul>

    <h3>Why Choose Nova for Your Graphic Design Needs?</h3>
    <ul>
        <li><strong>Creative Expertise:</strong> Our designers have a deep understanding of the latest design trends and techniques, ensuring innovative and effective solutions.</li>
        <li><strong>Brand-Focused Approach:</strong> We tailor our designs to align with your brand’s goals and target audience, delivering designs that resonate and make an impact.</li>
        <li><strong>Commitment to Quality:</strong> We are dedicated to producing high-quality designs that meet your expectations and contribute to your brand’s success.</li>
    </ul>

    <p>At <strong>Nova</strong>, we are passionate about creating designs that elevate your brand and drive results. Let us help you make a lasting impression with our expert graphic design services. Contact us today to discuss your design needs.</p>
                </div>



                </div>

                </div>


            </section>

            <section className='form-section mb-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>


<ContactSection></ContactSection>
        </>
    )
}

export default GraphicDesign
