import React from 'react'
// import { NavLink } from "react-router-dom"
import logo from '../assets/logo.svg';





export function Footer(props) {

    return (
        <>
             <footer>
             <a href='#home' className='logo text-center mx-auto d-block mb-5' title='Nova Crypto Marketing Agency'>  <img src={logo} title='Logo' alt='Nova Crypto Marketing Agency'></img></a>

             <div className="social-icons d-flex justify-content-center mb-4">
             <a className="twitter" rel='noreferrer' title='Twitter' href="https://x.com/nova_crypto_x" target="_blank">
                                        <i className="icon-social-twitter"></i>
                                    </a>

                                    <a className="telegram" rel='noreferrer' title='Telegram' href="https://t.me/agency_nova" target="_blank">
                                    <i className="bi bi-send"></i>
                                    </a>
                                    <a className="whatsapp" rel='noreferrer' title='Whatsapp' href="https://whatsapp.com/channel/0029VagPThE2phHDvDrIQn26" target="_blank">
                                    <i className="bi bi-whatsapp"></i>
                                    </a>

                                    <a className="facebook" rel='noreferrer' title='Facebook' href="https://www.facebook.com/NovaCryptoAgency/" target="_blank">
                                        <i className="icon-social-facebook"></i>
                                    </a>
                                    <a className="linkedin" rel='noreferrer' title='Linkedin' href="https://medium.com/@novacryptoagency" target="_blank">
                                    <i className="fa-brands fa-medium"></i>
                                    </a>
                                    <a className="email" rel='noreferrer' title='E-Mail' href="mailto:info@novacryptoagency.com" target="_blank">
                                        <i className="icon-envelope-open"></i>
                                    </a>
                                </div>

                <div className='col-lg-6 mx-auto'>
                  <nav id="main-nav" className="main-menu navbar navbar-expand-lg">

                                        <div className="collapse navbar-collapse" id="navbarMain">
                         
                                        <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <a href="/" className="nav-link">Home</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/about-us" className="nav-link">About Us</a>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a href="/marketing"  className="nav-link">Marketing</a>
      
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a href="/services" className="nav-link">Services</a>

                                                </li>
                                                <li className="nav-item">
                                                    <a href="/faqs" className="nav-link">FAQs</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/contact" className="nav-link">Contact</a>
                                                </li>
                                            </ul>

                                        </div>

                                    </nav>

                </div>

                <div className='col-lg-8 mx-auto text-center'>
                    <p className='copyright'>©2022 Nova Agency, All Rights Reserved</p>
                </div>
            </footer>
        </>
    )
}
