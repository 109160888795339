import React from 'react'
import Form from '../../components/form';
// import marketingbanner1 from '../../assets/marketing-strategy.jpg';
import ContactSection from '../../components/contact-section';
import { Helmet } from 'react-helmet';


function PlatformAds(props) {
    return (
        <>
    <Helmet>
        <title>Platform Ads - Nova Marketing</title>
        <meta name="keywords" content="Platform Ads" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12 breadcrumb-container'>
                    <h1>Platform Ads</h1>
                    <ul className="breadcrumbs">
                            <li><a href="/">Home</a></li>
                            <li>Platform Ads</li>
                        </ul>
                    
                    </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-5 my-5'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12'>
                    <h5>Ad Specialists</h5>
                    <h2>What are Platform Ads?
                    </h2>
                    <p>
                    One of the ideal advertising spaces for blockchain is the crypto portals used by every investor. These portals have high visitor traffic for both price tracking and current crypto news. The most commonly used portals, Cryptomarketcap and Coingecko, offer region/country-specific advertising on their websites.
</p>
<p>
At Nova, we plan and prepare your advertisements on crypto portals according to your marketing strategy. These ads are billed by the respective portals based on the cost-per-click model. Nova is only responsible for the management of the advertisement.
</p>
<p>
Crypto portals operate on a CPC (cost-per-click) basis similar to Google and Twitter Ads, but their audience targeting options are weaker compared to Google. This means your ads are shown to general crypto investors rather than being based on specific keywords or interests. However, some crypto portals offer the option to display ads through Google Ads. In this case, your ad must be approved by Google Ads. Ad campaigns that can be conducted directly with the portal are only subject to the portal's approval.
</p>



                </div>



                </div>

                </div>


            </section>

            <section className='form-section mb-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>


<ContactSection></ContactSection>
        </>
    )
}

export default PlatformAds
