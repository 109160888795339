import React from 'react'
import ContactSection from '../components/contact-section';


export default function faqs() {
    return (
        <>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>FAQs</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Home</a></li>
                                <li>FAQs</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section id='faqs' className='faqs-section py-5'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-lg-12'>
                            <div className="accordion accordion-flush accordion-faqs" id="accordion-faqs">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-1" aria-expanded="true" aria-controls="flush-collapseOne">
                                            What are the limits for the Ad budget?
                                        </button>
                                    </h2>
                                    <div id="flush-1" className="accordion-collapse collapse show" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            There are no specific lower or upper limits. We adjust campaigns based on realistic goals proportional to your budget. Regardless of your budget, you can contact us to learn for free what we can do for you.

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-2" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            Do you create digital materials?



                                        </button>
                                    </h2>
                                    <div id="flush-2" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            Yes. The foundation of marketing consists of digital materials that every investor needs to understand your project. In this scope, we create and update materials such as websites, pitch decks, banners, and videos.


                                        </div>
                                    </div>

                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-3" aria-expanded="false" aria-controls="flush-collapseThree">
                                            What is the service fee for consultancy?
                                        </button>
                                    </h2>
                                    <div id="flush-3" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            The initial stage is getting to know you and understanding your project, and this is free of charge. We either include our services in the campaign budget according to their areas or price them separately for web projects.


                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-4" aria-expanded="false" aria-controls="flush-collapseThree">
                                            Do you accept payment with tokens?
                                        </button>
                                    </h2>
                                    <div id="flush-4" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            Yes, we can accept token payments for some of our services. The option to pay with tokens depends on the liquidity and volatility of the project's token. However, for web services and CPC Ads, we accept stablecoins.

                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-5" aria-expanded="false" aria-controls="flush-collapseThree">
                                            What areas of expertise do you have?
                                        </button>
                                    </h2>
                                    <div id="flush-5" className="accordion-collapse collapse" data-bs-parent="#accordion-faqs">
                                        <div className="accordion-body">
                                            Nova Marketing Agency is comprised of experienced employees specialized in various fields. Marketing is a multifaceted and multilayered service. In this context, our team consists of marketing experts, developers, and designers.

                                        </div>                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <ContactSection></ContactSection>
        </>
    )
}
