import React from 'react'
import Carousel from "react-multi-carousel";
import methods from '../assets/methods.webp';
import materials from '../assets/materials.webp';
import xspace from '../assets/x-space.webp';
import community from '../assets/community.webp';

export default function BlogSlider() {



  const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

  return (
    <section className='blog-section gray-bg py-6'>
    <div className='container'>
        <div className='row'>
            <div className='col-lg-12'>
                <div className='feature-column mb-5'>
                    <p className='sub-title'>News & Blog
                    </p>
                    <h2>Latest News
                    </h2>

                </div>
            </div>
            <div className='col-lg-12'>
                <Carousel  responsive={responsive} arrows={false} autoPlay={true} 
                    autoPlaySpeed={2000} infinite={true}
                    transitionDuration={500} dotListclassName="custom-dot-list-style"
                    itemclassName="carousel-item-padding-0-px" draggable={true}
                    showDots={true}>


                    <div className='blog-slide-item mb-4 carousel-item-padding-40-px'>
                        <div className='image-box'>
                        <a href='/marketing-methods' title='Marketing Methods Overview'>
                            <img className='img-fluid' src={methods} title='Marketing Methods Overview' alt='Marketing Methods Overview'></img>
                        </a>
                        </div>
                        <div className='blog-summary'>
                            <span className='blog-date'>06 Aug, 2024</span>
                            <a href='/marketing-methods' title='Marketing Methods Overview'>
                            <h3>Marketing Methods Overview</h3>
                            </a>
                            <a className='button-style-2' href='/marketing-methods' title='Marketing Methods Overview'><i className="bi bi-arrow-right-short"></i></a>

                        </div>
                    </div>

                    <div className='blog-slide-item mb-4 carousel-item-padding-40-px'>
                        <div className='image-box'>

                        <a href='/marketing-materials' title='What Materials Do You Need?'>
                            <img className='img-fluid' src={materials} title='What Materials Do You Need?' alt='What Materials Do You Need?'></img>
                      </a>
                        </div>
                        <div className='blog-summary'>
                            <span className='blog-date'>06 Aug, 2024</span>
                            <a href='/marketing-materials' title='What Materials Do You Need?'>
                            <h3>What Materials Do You Need?</h3>
                            </a>
                            <a className='button-style-2' href='/marketing-materials' title='What Materials Do You Need?'><i className="bi bi-arrow-right-short"></i></a>

                        </div>
                    </div>

                    <div className='blog-slide-item mb-4 carousel-item-padding-40-px'>
                        <div className='image-box'>
                        <a href='/x-Space-for-the-crypto-industry' title='X-Space for the Crypto Industry'>
                            <img className='img-fluid' src={xspace} title='X-Space for the Crypto Industry' alt='Blog 01'></img>
                            </a>
                        </div>
                        <div className='blog-summary'>
                            <span className='blog-date'>06 Aug, 2024</span>
                            <a href='/x-Space-for-the-crypto-industry' title='X-Space for the Crypto Industry'>
                            <h3>X-Space for the Crypto Industry</h3>
                            </a>
                            <a className='button-style-2' href='/x-Space-for-the-crypto-industry' title='X-Space for the Crypto Industry'><i className="bi bi-arrow-right-short"></i></a>

                        </div>
                    </div>

                    <div className='blog-slide-item mb-4 carousel-item-padding-40-px'>
                        <div className='image-box'>
                        <a href='/community-building-and-communication' title='X-Space for the Crypto Industry'>
                            <img className='img-fluid' src={community} title='Community Building and Communication' alt='Community Building and Communication'></img>
                       </a>
                        </div>
                        <div className='blog-summary'>
                            <span className='blog-date'>06 Aug, 2024</span>
                            <a href='/community-building-and-communication' title='X-Space for the Crypto Industry'>
                            <h3>Community Building and Communication</h3>
                            </a>
                            <a className='button-style-2' href='/community-building-and-communication' title='Community Building and Communication'><i className="bi bi-arrow-right-short"></i></a>

                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    </div>
</section>
  )
}
