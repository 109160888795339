import React from 'react'
import Form from '../../components/form';
import marketingbanner1 from '../../assets/marketing-strategy.jpg';
import ContactSection from '../../components/contact-section';
import { Helmet } from 'react-helmet';


function InfluencerMarketing(props) {
    return (
        <>
                                      <Helmet>
        <title>Crypto Influencer Marketing - Nova Marketing</title>
        <meta name="keywords" content="influencer marketing" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12 breadcrumb-container'>
                    <h1>Influencer Marketing</h1>
                    <ul className="breadcrumbs">
                            <li><a href="/">Home</a></li>
                            <li>Influencer Ads</li>
                        </ul>
                    
                    </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-5 my-5'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12'>
                    <h5>Pro Experts</h5>
                    <h2>Influencer Marketing
                    </h2>
                    <p>
                    Influencer marketing holds a significant place, especially in the crypto ecosystem. Influencers and Key Opinion Leaders (KOLs) who closely follow markets and crypto projects are seen as guiding resources for people seeking information on the same topics. If you work with influencers who align with your brand and products, you can reach audiences interested in similar topics more effectively and easily. For example, the audience of an influencer well-known in the DeFi space will consist of people interested in and investing in DeFi solutions. Influencers facilitate access to your target audience, and their recommendations are more impactful on the audience.
</p>
<p>
At Nova, we collaborate with influencers who have proven themselves in content creation. We thoroughly explain your project to influencers 
that match your product-service category. We prepare the necessary content and visual materials to ensure we reach the relevant audience
 in the most effective way. The main cryptocurrency categories related to the influencers we work with are as follows:


</p>








<div className='row mt-5'>
<div className='col-6'>
                <ul className="check-list mb-4">
                   <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>DeFi (Decentralized Finance): Influencers in this category focus on decentralized financial solutions, including decentralized exchanges, lending platforms, and yield farming.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>NFTs (Non-Fungible Tokens): Influencers specializing in NFTs cover topics related to digital art, collectibles, and virtual assets.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Blockchain Technology: These influencers provide insights into the underlying technology of cryptocurrencies, including smart contracts, blockchain development, and innovative applications.
                                </p>
                            </div>
                        </li>

                    </ul>

                    </div>
                    <div className='col-6'>
                <ul className="check-list mb-4">
                <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Cryptocurrency Trading and Investment: Influencers in this category offer analysis, trading tips, and investment strategies for various cryptocurrencies.

                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Crypto Gaming: Influencers focus on blockchain-based games and play-to-earn models, exploring the intersection of gaming and cryptocurrency.
                                </p>
                            </div>
                        </li>

                        <li>
                            <div className="list-icon"> <i className="fa-solid fa-check"></i> </div>
                            <div className="list-text">
                                <p>Metaverse: Influencers in this category explore virtual worlds and metaverse projects, including virtual real estate and digital identity.
                                </p>
                            </div>
                        </li>

                    </ul>


                    </div>
                    <p>
                    By collaborating with influencers in these categories, we ensure targeted and effective reach to audiences interested in specific aspects of the cryptocurrency ecosystem.
                    </p>
                    </div>
                </div>

                <div className='col-lg-4 d-none'>
                <img src={marketingbanner1} className='img-fluid radius' title='Marketing Strategy' alt='Marketing Strategy'></img>
                </div>


                </div>

                </div>


            </section>

            <section className='form-section mb-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>


<ContactSection></ContactSection>
        </>
    )
}

export default InfluencerMarketing
