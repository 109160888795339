import React from 'react'
import Form from '../components/form'
import ContactSection from '../components/contact-section';


export default function Touch() {
  return (
   <>
               <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Make an enquiry</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Home</a></li>
                                <li>Get in Touch</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section className='form-section py-6'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>

            <ContactSection></ContactSection>
   </>
  )
}
