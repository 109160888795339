import React from 'react'
import Form from '../../components/form';
// import marketingbanner1 from '../../assets/marketing-strategy.jpg';
import { Helmet } from 'react-helmet';


function XAds(props) {
    return (
        <>
                                    <Helmet>
        <title>X Ads - Nova Crypto Marketing</title>
        <meta name="keywords" content="X Ads" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12 breadcrumb-container'>
                    <h1>X (Twitter) Ads</h1>
                    <ul className="breadcrumbs">
                            <li><a href="/">Home</a></li>
                            <li>X Ads</li>
                        </ul>
                    
                    </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 py-5 my-5'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12'>
                    <h5>Ad Pros</h5>
                    <h2>Why X Ads?
                    </h2>
                    <p>
                    Especially for the cryptocurrency/blockchain ecosystem, the largest habitat is X. Investors, influencers, KOLs, and projects predominantly use X. Therefore, it is the top priority for reaching your target audience. Besides promotions done through community engagement, you can also utilize X's Ads service.
                    </p>
                    <p>
X Ads, with its advanced audience targeting tools, allows you to reach the audience you want. In particular, the follower look-alikes targeting is the most ideal method for your promotional goals. Additionally, the filters offered under X Ads’ interests section enable you to reach your target audience more specifically.
</p>
<p>
At Nova, we create and manage your X Ads campaigns. We prepare a detailed report on the region, audience, keywords, target accounts, and ad schedule needed for your campaign, and we report the campaign results with statistics.
                    </p>


                </div>



                </div>

                </div>


            </section>

            <section className='form-section mb-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>


            <section className='contact-section'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <p>We develop digital future
        </p>
        <h2 className='mb-5'><span>Let's</span> Talk?
        </h2>
        <a className='button-default' href='/contact'>Make an enquiry</a>
        </div>
        </div>
    </div>
</section>
        </>
    )
}

export default XAds
