import React from 'react';
import { Routes, Route } from "react-router-dom"
import { Layout } from './pages/layout';
import { Index } from './pages';
import Terms from './pages/terms';
// import './App.css';
import './assets/icons.min.css';
import About from './pages/about-us';
import Marketing from './pages/marketing';
import Services from './pages/services';
import Faqs from './pages/faqs';
import Contact from './pages/contact';
import Touch from './pages/touch';
import GoogleAds from './pages/subpages/google-ads';
import MarketingStrategy from './pages/subpages/marketing-strategy';
import InfluencerMarketing from './pages/subpages/influencer-marketing';
import PlatformAds from './pages/subpages/platform-ads';
import XAds from './pages/subpages/x-ads';
import CustomCampaigns from './pages/subpages/custom-campaigns';
import WebDevelopment from './pages/subpages/web-development';
import GraphicDesign from './pages/subpages/graphic-design';
import SeoSem from './pages/subpages/seo-sem';
import VideoPromo from './pages/subpages/video-promo';

import MarketingMethods from './blog/marketing-methods';
import MarketingMaterials from './blog/marketing-materials';
import XSpaceForCrypto from './blog/x-space-for-crypto';
import CommunityBuilding from './blog/community-building';
import 'animate.css';
import "animate.css/animate.compat.css"





function App() {


  return (
    <>


    <Routes>  
      <Route path='/' element={<Layout />} >
        <Route index={true} element={<Index />} />
       <Route path='terms' element={<Terms />} /> 
       <Route path='about-us' element={<About />} />
       <Route path='marketing' element={<Marketing />} /> 
       <Route path='services' element={<Services />} />
       <Route path='faqs' element={<Faqs />} /> 
       <Route path='contact' element={<Contact />} /> 
       <Route path='get-in-touch' element={<Touch />} /> 
       <Route path='google-ads' element={<GoogleAds />} />
       <Route path='marketing-strategy' element={<MarketingStrategy />} />
       <Route path='influencer-marketing' element={<InfluencerMarketing />} />
       <Route path='platform-ads' element={<PlatformAds />} />
       <Route path='x-ads' element={<XAds />} />
       <Route path='custom-campaigns' element={<CustomCampaigns />} /> 
       <Route path='web-development' element={<WebDevelopment />} /> 
       <Route path='graphic-design' element={<GraphicDesign />} />
       <Route path='seo-sem' element={<SeoSem />} /> 
       <Route path='video-promo' element={<VideoPromo />} />
       <Route path='marketing-materials' element={<MarketingMaterials />} />

       <Route path='marketing-methods' element={<MarketingMethods />} />
       <Route path='x-Space-for-the-crypto-industry' element={<XSpaceForCrypto />} />
       <Route path='community-building-and-communication' element={<CommunityBuilding />} />
       

       

       
      </Route>
    </Routes> 
    </>


    
  );    
}   



export default App;
