import React, { useState } from 'react';

export default function FormApp() {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  function Submit(e) {
    e.preventDefault();
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);

    fetch(
      "https://script.google.com/macros/s/AKfycbyorjBEiUPTJGhsDrVksItOuBxGYdTEgWXZ9c_hvFCOlSJBe6SV1IJ5GmrfQfpTH-AK/exec",
      {
        method: "POST",
        body: formDatab
      }
    )
      .then((res) => res.text())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
        setError('');  // Clear any previous error
      })
      .catch((error) => {
        console.log(error);
        setError('Form submission failed. Please try again.');
        setSubmitted(false);  // Reset submitted state if there's an error
      });
  }

  return (

      <div className="form-container">
        <form className="form" onSubmit={(e) => Submit(e)}>
          <div className='row'>
            <div className='col-lg-6 mb-3'>
              <input className='form-control' placeholder="Your Name" name="Name" type="text" required />
            </div>
            <div className='col-lg-6 mb-3'>
              <input className='form-control' placeholder="Your Email" name="Email" type="email" required />
            </div>
            <div className='col-lg-12 mb-3'>
              <input className='form-control' placeholder="Website" name="Website" type="text" required />
            </div>
            <div className='col-lg-12 mb-3'>
              <textarea className='form-control' placeholder="Your Message" name="Message" rows="5" required />
            </div>
            <div className='col-lg-12'>
              <button type="submit" className='button-default mt-4'>Send Message</button>
            </div>
          </div>
        </form>
        {submitted && <p className="alert alert-success mt-4">Form submitted successfully!</p>}
        {error && <p className="error-message">{error}</p>}
      </div>

  );
}
