import React from 'react'
import ContactSection from '../components/contact-section';
// import Form from '../components/form'
import { Helmet } from 'react-helmet';

function Marketing(props) {
    return (
        <>
        <Helmet>
        <title>Nova Crypto Marketing Agency</title>
        <meta name="keywords" content="cryto marketing Services" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 breadcrumb-container'>
                            <h1>Crypto Marketing</h1>
                            <ul className="breadcrumbs">
                                <li><a href="/">Home</a></li>
                                <li>Marketing</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>




            <section className='content-style-02 py-5 my-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <h2>Transforming Visions into Reality

                            </h2>

                        </div>
                        <div className='col-lg-7'>
                            <p>
                            We provide tailored marketing solutions designed to amplify your brand’s reach and achieve success. Our expert team specializes in strategies that drive continuous growth in the dynamic crypto landscape. From influencer marketing to platform ads, and custom campaigns to Google and X (Twitter) Ads, we cover every aspect to ensure maximum visibility and impact. Partner with us for strategic, data-driven campaigns that deliver measurable results and elevate your brand in the competitive market.
                            </p>

                        </div>

                    </div>

                </div>


            </section>

            <section id='features' className='key-features-section pb-5'>


                <div className='container'>
                    <div className="row justify-content-center items">

                        <div className="col-12 col-md-6 col-lg-4 item">

                            <div className="card help-card featured">
                                <i className="fa-regular fa-chess-queen"></i>
                                <a href='/marketing-strategy'>
                                <h4>Marketing Strategy</h4>
                                </a>
                                <p>We analyze your strategy, define your goals, and implement the best marketing tactics.</p>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 item">
                            <div className="card help-card">
                                <i className="fa-regular fa-lightbulb"></i>
                                <a href='/influencer-marketing'>
                                <h4>Influencer Marketing</h4>
                                </a>
                                <p>Collaborating with top influencers, we connect your brand with the right audience.</p>                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 item">

                            <div className="card help-card">
                            <i className="fa-regular fa-window-restore"></i>
                                <a href='/platform-ads'>
                                <h4>Platform Ads</h4>
                                </a>
                                <p>We place targeted ads on top crypto platforms, reaching engaged and relevant audiences.</p>
                            </div>
                        </div>





                        <div className="col-12 col-md-6 col-lg-4 item">

                            <div className="card help-card">
                            <i className="fa-brands fa-google"></i>
                                <a href='/google-ads'>
                                <h4>Google Ads
                                </h4>
                                </a>
                                <p>Utilizing Google’s network, we create ads that drive traffic and increase conversions</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 item">

                            <div className="card help-card">
                            <i className="fa-brands fa-x-twitter"></i>
                                <a href='/x-ads'>
                                <h4>X (Twitter) Ads</h4>
                                </a>
                                <p>We design and manage Twitter ads that amplify your brand’s presence and engagement.</p>
                                </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 item">

                            <div className="card help-card">
                                <i className="fa-solid fa-compass-drafting"></i>
                                <a href='/custom-campaigns'>
                                <h4>Custom Campaigns</h4>
                                </a>
                                <p>We develop tailored campaigns that align with your unique goals and market needs</p>
                            </div>
                        </div>


                    </div>
                </div>


            </section>




            <ContactSection></ContactSection>


        </>
    )
}

export default Marketing
