import React from 'react'
import Form from '../../components/form';
// import marketingbanner1 from '../../assets/marketing-strategy.jpg';
import ContactSection from '../../components/contact-section';
import { Helmet } from 'react-helmet';


function VideoPromo(props) {
    return (
        <>
                    <Helmet>
        <title>Video Promo - Nova Crypto Marketing</title>
        <meta name="keywords" content="Video Promo" />

      </Helmet>
            <section className='breadbrumb-section'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12 breadcrumb-container'>
                    <h1>Video Promo
                    </h1>
                    <ul className="breadcrumbs">
                            <li><a href="/">Home</a></li>
                            <li>Video Promo
                            </li>
                        </ul>
                    
                    </div>
                    </div>
                </div>
            </section>

            <section className='content-style-01 content-style-03 py-5 my-5'>
                <div className='container'>
                <div className='row'>
                <div className='col-lg-12'>
                    <h5>Video Pros</h5>
                    <h2>Video Promo Services

                    </h2>
                    <p>At <strong>Nova</strong>, we specialize in creating captivating video promos that effectively communicate your message and engage your audience. Our video promo services are designed to elevate your brand’s presence and drive impactful results through high-quality visual content. Our Video Promo Services Include:</p>


<ul>
    <li><strong>Concept Development:</strong> We work with you to develop a compelling concept that aligns with your brand’s goals and resonates with your target audience.</li>
    <li><strong>Scriptwriting:</strong> Our team crafts engaging scripts that clearly convey your message and maintain viewer interest throughout the video.</li>
    <li><strong>Storyboarding:</strong> We create detailed storyboards to visualize the flow and structure of your video promo, ensuring a smooth production process.</li>
    <li><strong>Filming and Production:</strong> Using state-of-the-art equipment, we capture high-quality footage that brings your concept to life with professional cinematography.</li>
    <li><strong>Video Editing:</strong> Our expert editors enhance your footage with precise cuts, transitions, and effects to produce a polished and visually appealing final product.</li>
    <li><strong>Sound Design:</strong> We incorporate background music, voiceovers, and sound effects to complement your visuals and enhance the overall impact of your video promo.</li>
    <li><strong>Final Delivery:</strong> We provide your video promo in various formats suitable for online platforms, social media, and other marketing channels.</li>
</ul>

<h3>Why Choose Nova for Your Video Promo Needs?</h3>
<ul>
    <li><strong>Creative Excellence:</strong> Our team of creative professionals is dedicated to producing unique and engaging video content that captures your brand’s essence.</li>
    <li><strong>Tailored Solutions:</strong> We tailor our video promos to meet your specific needs and objectives, ensuring a personalized approach to your project.</li>
    <li><strong>Commitment to Quality:</strong> We are committed to delivering high-quality videos that make a lasting impression and drive results for your brand.</li>
</ul>

<p>At <strong>Nova</strong>, we are passionate about creating video promos that make your brand shine. Let us help you tell your story with compelling visual content that engages and inspires. Contact us today to start your video promo project.</p>


                </div>



                </div>

                </div>


            </section>

            <section className='form-section mb-5'>
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
        <div className='content-style-01'>
    <h5>Any Questions?</h5>
    <h2>Write Us</h2>
    </div>
        <Form></Form>
        </div>
        </div>
    </div>
</section>


<ContactSection></ContactSection>
        </>
    )
}

export default VideoPromo
